import React from "react";
import { Link } from "rebass/styled-components";
import Tippy from "@tippy.js/react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SocialLink as SocialLinkType } from "../types";
import { getIconDefinition } from "../utils/icons";

type Props = SocialLinkType & {
	invert?: boolean;
};

const SocialLink = ({ icon, name, url, invert }: Props) => {
	const iconDefinition = getIconDefinition(icon);
	if (!iconDefinition) return null;

	return (
		<Tippy
			content={name}
			placement="bottom"
			trigger="mouseenter focus"
			arrow={false}
		>
			<IconLink
				href={url}
				target="_blank"
				invert={invert}
				rel="noreferrer"
				aria-label={name}
			>
				<FontAwesomeIcon icon={iconDefinition} title={name} />
			</IconLink>
		</Tippy>
	);
};

const IconLink = styled(Link)<{ invert?: boolean }>`
	transition: opacity 0.4s;
	color: ${({ theme, invert }) =>
		invert ? theme.colors.darkPrimary : theme.colors.lightPrimary};
	text-decoration: none;

	&:hover {
		opacity: 0.7;
	}
`;

export default SocialLink;
