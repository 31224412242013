import React from "react";
import { Box, Image, Flex } from "rebass/styled-components";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Fade } from "react-awesome-reveal";
import Section from "../components/Section";
import Triangle from "../components/Triangle";
import components from "../components/MarkdownComponents";
import { useAboutQuery } from "../queries/useAboutQuery";
import { SECTION } from "../utils/constants";

const About = () => {
	const { markdown, logo } = useAboutQuery();

	/** Prevents the dark triangle from overlapping with the dark text. */
	const customStyles = `
		@media (max-width: 1400px) {
			padding-top: 10vh;
		}
		@media (max-width: 1000px) {
			padding-top: 20vh;
		}
	`;

	return (
		<Section.Container id={SECTION.about} Background={Background}>
			<Section.Header
				name={SECTION.about}
				styles={customStyles}
				icon="👋🏻"
				label="waving hand"
			/>

			<Flex justifyContent="center" alignItems="center" flexWrap="wrap">
				<Box width={[1, 1, 4 / 6]} px={[1, 2, 4]} mt={2}>
					<Fade direction="down" triggerOnce>
						<ReactMarkdown children={markdown} components={components} />
					</Fade>
				</Box>

				<Box
					width={[1, 1, 2 / 6]}
					style={{ maxWidth: "300px", margin: "auto" }}
				>
					<Fade direction="right" triggerOnce>
						<ProfilePicture mt={[4, 4, 0]} ml={[0, 0, 1]} {...logo} />
					</Fade>
				</Box>
			</Flex>
		</Section.Container>
	);
};

const ProfilePicture = styled(Image)`
	border-radius: 50%;
	transition: all 0.4s ease-out;

	&:hover {
		border-radius: 20%;
	}
`;

const Background = () => (
	<>
		<Triangle
			color="secondaryAccent"
			height={["20vh", "40vh"]}
			width={["75vw", "70vw"]}
			position="top-right"
		/>

		<Triangle
			color="darkPrimary"
			height={["25vh", "20vh"]}
			width={["100vw", "100vw"]}
		/>

		<Triangle
			color="lightSecondary"
			height={["50vh", "20vh"]}
			width={["50vw", "50vw"]}
			position="bottom-left"
		/>
	</>
);

export default About;
